import React from "react";

export default function InfoItem({ title, children }) {
  return (
    <div>
      <div className="small">{title}</div>
      <div><b>{children}</b></div>
    </div>
  );
}
