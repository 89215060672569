import { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";

import "./Users.scss";

import {
  transactionService,
  walletAddressService as walletService,
  web3ProviderService as web3Provider,
  kycService,
  utilities,
} from "../../../services";
import {
  Transaction,
  WalletAddress,
  User,
} from "../../../services/models";
import { userHelper as helper } from "./user-helper";

import Alert from "../../common/Alert";
import If from "../../common/If";
import Spinner from "../../common/Spinner";
import DeclarationBody from "./DeclarationBody";

type Props = {
  user: User;
  users: User[];
  onHide: () => void;
};

const expectedChainId = "0x" + process.env.REACT_APP_CHAIN_ID;

const UserDetailsDialog = ({ user, users, onHide }: Props) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [balance, setBalance] = useState(0);
  const [wallet, setWallet] = useState<WalletAddress>(null);
  const [kycStatus, setKycStatus] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    resetData();
    if (user) {
      fetchData();
    }
  }, [user]);

  const resetData = () => {
    setTransactions([]);
    setBalance(0);
    setWallet(null);
    setKycStatus(null);
    setError("");
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      let transactions = await transactionService.find({
        userId: user.id,
      });
      setTransactions(transactions);

      const wallet = await walletService.fetchActiveFor(user.id);
      setWallet(wallet);

      if (wallet) {
        if (web3Provider.getChainId() !== expectedChainId) {
          await web3Provider.switchToAppChain();
        }
        const balance = await web3Provider.fetchBalanceFor(
          wallet.walletAddress
        );
        setBalance(balance);
      }

      if (user.applicant) {
        const kycStatus = await kycService.fetchKycStatusFor(user.id);
        console.log(kycStatus);
        setKycStatus(kycStatus);
      }
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  const getKycIconClass = (key) => {
    const passed = kycStatus[key]?.reviewResult?.reviewAnswer === "GREEN";
    const started = !!kycStatus[key];
    let color = passed
      ? "text-success"
      : started
      ? "text-warning"
      : "text-danger";
    switch (key) {
      case "IDENTITY":
        return "person-badge " + color;
      case "PROOF_OF_RESIDENCE":
        return "house-fill " + color;
      case "SELFIE":
        return "person-circle " + color;
    }
  };

  return (
    <Modal
      show={!!user}
      onHide={onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {user?.data.name} {user?.data.surname}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="users">
          <div className="text-center m-3">
            <Spinner show={loading} />
          </div>
          <If condition={!loading}>
            <div>
              <b>Wallet:</b>
              <div className="mx-2">
                {wallet && (
                  <>
                    <div>Address: {wallet?.walletAddress}</div>
                    <div>Balance: {utilities.formatRLT(balance)}</div>
                  </>
                )}
                {!wallet && <div className="text-warning">Not Connected</div>}
              </div>
            </div>

            <div className="mt-3">
              <b>KYC:</b>
              <div className="mx-2 d-flex align-items-center">
                <div>
                  Status:{" "}
                  <span className={helper.getApplicantColor(user?.applicant)}>
                    {helper.getApplicantStatus(user?.applicant)}
                  </span>
                </div>
                {user?.applicant && kycStatus && (
                  <div className="d-flex">
                    {Object.keys(kycStatus).map((x) => (
                      <OverlayTrigger
                        key={x}
                        trigger={["hover", "focus"]}
                        placement="bottom"
                        overlay={
                          <Popover>
                            <Popover.Body>{x}</Popover.Body>
                          </Popover>
                        }
                      >
                        <h5 className="m-0">
                          <i className={"ms-2 bi bi-" + getKycIconClass(x)} />
                        </h5>
                      </OverlayTrigger>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3">
              <b>Sophisticated Investor</b>
              <div className="mx-3">
                Status:{" "}
                <span className={helper.getDeclarationColor(user?.declaration)}>
                  {helper.getDeclarationStatus(user?.declaration)}
                </span>
                {user?.declaration && (
                  <DeclarationBody
                    declaration={user.declaration}
                    users={users}
                  />
                )}
              </div>
            </div>
          </If>
          <Alert error={error} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserDetailsDialog;
