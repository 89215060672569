import "./text-area.scss";

type TextAreaProps = {
  value: string | number;
  onChange: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  className?: string;
  [key: string]: any;
};

const TextArea = ({
  value,
  onChange,
  required,
  disabled,
  placeholder,
  label,
  className,
  ...rest
}: TextAreaProps) => {
  return (
    <div className={"app-input " + className}>
      <textarea
        {...rest}
        className={value ? "has-value" : ""}
        required={required}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      ></textarea>
      <label className={value ? "has-value" : ""}>{label}</label>
    </div>
  );
};
export default TextArea;
