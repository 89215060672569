import { Model } from "@appstrax/database";

export enum TransactionStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
}

export enum PaymentStatus {
  pending = 'pending',
  paid = 'paid',
  cancelled = 'cancelled',
  failed = 'failed',
}

export enum PaymentMethod {
  card = 'card',
  uniswap = 'uniswap',
  bankTransfer = 'bankTransfer',
}

export interface TransactionSignature {
  userId: string;
  signedAt: Date;
  walletAddress: string;
}

export class Transaction extends Model {
  rltAmount: number = 0;
  poundAmount: number = 0; // £
  
  coinPriceId: string = '';

  transactionFeeId: string = '';
  transactionFeeAmount: number = 0; // this amount is in RTL
  includesOnceOffFee: boolean = false;

  userId: string = '';

  paymentReference: string = '';
  paymentMethod: string = PaymentMethod.bankTransfer;
  paymentStatus: string = PaymentStatus.pending;

  transactionStatus: string = TransactionStatus.pending;

  confirmed: boolean = false;
  confirmedBy: string = ''; // the user who confirmed the bank transfer
  confirmedAt: Date = null;

  transferred: boolean = false;
  transferredBy: string = ''; // the user who initiated the transfer
  transferredAt: Date = null;

  walletAddress: string = ''; // this is the crypto wallet address
  chainHash: string = ''; // this is the on chain reference
  gnosisTransaction: string = ''; // this is a json string of the Gnosis Transaction
  signatures: TransactionSignature[] = [];
}