import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { coinPriceService } from "../../../services/coin-price.service";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { coinPriceCreated } from "../../../redux/slices/coin-price-slice";

import Button from "../../common/Button";
import Alert from "../../common/Alert";
import { CoinPrice } from "../../../services/models/coin-price";
import { utilities } from "../../../services/utilities";
import Input from "../../common/input/Input";

type Props = {
  show: boolean;
  onHide: () => void;
};

const ChangePriceDialog = ({ show, onHide }: Props) => {
  const user = useAppSelector((state) => state.auth.user);
  const currentPrice = useAppSelector((state) => state.coinPrice.price);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [price, setPrice] = useState<number | null>(0);

  useEffect(() => {
    setPrice(currentPrice?.price || 0);
  }, [currentPrice]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      let coinPrice = new CoinPrice();
      coinPrice.price = price || 0;
      coinPrice.userId = user?.id || "";
      coinPrice = await coinPriceService.save(coinPrice);
      coinPrice = utilities.serialize(coinPrice);
      dispatch(coinPriceCreated(coinPrice));

      // TODO: show success message

      onHide();
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Set Coin Price</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={(e) => onFormSubmit(e)}>
          <div>
            Please note that by changing the price, this will be the new price
            that users will pay for the coin.
          </div>

          <Input
            required
            className="mb-4"
            label="Coin Price"
            value={price || ""}
            onChange={(e) => setPrice(parseFloat(e))}
            type="number"
            step=".01"
          />
        </form>

        <Alert error={error} />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()} variant="info">
          Cancel
        </Button>
        <Button
          variant="success"
          loading={loading}
          onClick={(e) => onFormSubmit(e)}
        >
          Set Coin Price
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePriceDialog;
