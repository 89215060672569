import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./Transactions.scss";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { usersFetched } from "../../../redux/slices/users-slice";
import {
  transactionsFetched,
  transactionUpdated,
} from "../../../redux/slices/transactions-slice";

import { PaymentStatus, TransactionStatus } from "../../../services/models";
import {
  transactionService,
  utilities,
  userService,
  emailService,
} from "../../../services";

import Alert from "../../common/Alert";
import If from "../../common/If";
import Spinner from "../../common/Spinner";
import InfoItem from "./InfoItem";
import Button from "../../common/Button";
import ConfirmDialog from "../../common/ConfirmDialog";
import TransactionSignatures from "./TransactionSignatures";

const TransactionDetails = () => {
  const dispatch = useAppDispatch();

  const { transactionId } = useParams();

  const users = useAppSelector((state) => state.users.users);
  const user = useAppSelector((state) => state.auth.user);
  const transaction = useAppSelector((state) =>
    state.transactions.transactions.find((x) => x.id === transactionId)
  );

  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");

  const [showPaymentConfirmed, setShowPaymentConfirmed] = useState(false);
  const [showDeclineTransaction, setShowDeclineTransaction] = useState(false);

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    setFetching(true);

    try {
      let users = await userService.find();
      let transactions = await transactionService.find();

      transactions = utilities.serializeArr(transactions);

      dispatch(usersFetched(users));
      dispatch(transactionsFetched(transactions));
    } catch (err) {
      setError(err.message);
    }

    setFetching(false);
  };

  const getUser = (userId: string) => {
    if (!userId) return "";

    const user = users.find((x) => x.id === userId);
    return `${user?.data.name[0]}. ${user?.data.surname}`;
  };

  async function approvePayment() {
    setShowPaymentConfirmed(false);
    setSaving(true);
    try {
      let tx = utilities.serialize(transaction);
      tx.confirmed = true;
      tx.confirmedBy = user.id;
      tx.confirmedAt = new Date();
      tx.paymentStatus = PaymentStatus.paid;
      tx = await transactionService.save(tx);

      tx = utilities.serialize(tx);
      dispatch(transactionUpdated(tx));
    } catch (err) {
      setError(err);
    }
    setSaving(false);
  }

  async function declineTransaction() {
    setShowDeclineTransaction(false);
    setSaving(true);
    try {
      let tx = utilities.serialize(transaction);
      tx.confirmed = true;
      tx.confirmedBy = user.id;
      tx.confirmedAt = new Date();
      tx.paymentStatus = PaymentStatus.failed;
      tx.transactionStatus = TransactionStatus.declined;
      tx = await transactionService.save(tx);

      tx = utilities.serialize(tx);
      dispatch(transactionUpdated(tx));
      emailService.sendTransactionDeclinedEmail(tx);
    } catch (err) {
      setError(err);
    }
    setSaving(false);
  }

  return (
    <div className="m-3 transactions">
      <If condition={fetching}>
        <div className="text-center">
          <div className="mt-3">
            <Spinner />
          </div>
        </div>
      </If>

      {!fetching && transaction && (
        <>
          <h4 className="text-secondary">Transaction Details</h4>
          <div className="row">
            <div className="col-6 col-md-4 mt-3">
              <InfoItem title={"User"}>{getUser(transaction.userId)}</InfoItem>
            </div>

            <div className="col-6 col-md-4 mt-3">
              <InfoItem title={"RLT Amount"}>
                {utilities.formatRLT(transaction.rltAmount)}
              </InfoItem>
            </div>

            <div className="col-6 col-md-4 mt-3">
              <InfoItem title={"Transaction Fee"}>
                {utilities.formatRLT(transaction.transactionFeeAmount)}
              </InfoItem>
            </div>

            <div className="col-6 col-md-4 mt-3">
              <InfoItem title={"British Pounds Payable"}>
                {utilities.formatPounds(transaction.poundAmount)}
              </InfoItem>
            </div>

            <div className="col-6 col-md-4 mt-3">
              <InfoItem title={"Payment Method"}>
                {utilities.getPaymentMethod(transaction)}
              </InfoItem>
            </div>

            <div className="col-6 col-md-4 mt-3">
              <InfoItem title={"Payment Status"}>
                {utilities.getPaymentStatus(transaction)}
              </InfoItem>
            </div>

            <div className="col-6 col-md-4 mt-3">
              <InfoItem title={"Payment Reference"}>
                {transaction.paymentReference}
              </InfoItem>
            </div>
          </div>

          <If condition={!transaction.confirmed}>
            <h4 className="text-secondary mt-5">
              Pending Payment Confirmation
            </h4>

            <div className="text-center mt-3">
              <p>
                Please confirm that{" "}
                <b>{utilities.formatPounds(transaction.poundAmount)}</b> has
                been payment reference of <b>{transaction.paymentReference}</b>
              </p>
              <div>
                <Button
                  variant="success"
                  loading={saving}
                  onClick={() => setShowPaymentConfirmed(true)}
                >
                  Confirm Payment
                </Button>

                <Button
                  variant="danger"
                  className="ms-3"
                  loading={saving}
                  onClick={() => setShowDeclineTransaction(true)}
                >
                  Decline Payment
                </Button>
              </div>
            </div>
          </If>

          {transaction.confirmed &&
            transaction.paymentStatus === PaymentStatus.paid && (
              <div>
                <h4 className="mt-5 text-secondary">Payment Confirmation</h4>
                <div className="row">
                  <div className="col-6 col-md-4 mt-3">
                    <InfoItem title={"Confirmed By"}>
                      {getUser(transaction.confirmedBy)}
                    </InfoItem>
                  </div>
                  <div className="col-6 col-md-4 mt-3">
                    <InfoItem title={"Confirmed At"}>
                      {utilities.formatDateTime(transaction.confirmedAt)}
                    </InfoItem>
                  </div>
                </div>

                <TransactionSignatures
                  transaction={transaction}
                  users={users}
                />
              </div>
            )}

          {transaction.confirmed &&
            transaction.transactionStatus === TransactionStatus.declined && (
              <div>
                <h4 className="mt-5 text-secondary">Transaction Declined</h4>
                <div className="row">
                  <div className="col-6 col-md-4 mt-3">
                    <InfoItem title={"Declined By"}>
                      {getUser(transaction.confirmedBy)}
                    </InfoItem>
                  </div>
                  <div className="col-6 col-md-4 mt-3">
                    <InfoItem title={"Declined At"}>
                      {utilities.formatDateTime(transaction.confirmedAt)}
                    </InfoItem>
                  </div>
                </div>
              </div>
            )}
        </>
      )}

      <Alert error={error} />

      <ConfirmDialog
        show={showPaymentConfirmed}
        onHide={() => setShowPaymentConfirmed(false)}
        onConfirm={() => approvePayment()}
        title="Confirm Payment"
        body={
          "Are you sure " +
          utilities.formatPounds(transaction?.poundAmount) +
          " has been paid with a payment reference of " +
          transaction?.paymentReference
        }
      />

      <ConfirmDialog
        show={showDeclineTransaction}
        onHide={() => setShowDeclineTransaction(false)}
        onConfirm={() => declineTransaction()}
        title="Decline Transaction"
        body={
          "Are you sure you want to decline this transaction for" +
          utilities.formatPounds(transaction?.poundAmount) +
          "with a payment reference of " +
          transaction?.paymentReference +
          "?"
        }
      />
    </div>
  );
};

export default TransactionDetails;
