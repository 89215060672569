import { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { auth } from "@appstrax/auth";

import "./App.scss";
import { useAppSelector, useAppDispatch } from "./redux/hooks";
import { authStateChanged } from "./redux/slices/auth-slice";

import Loading from "./components/common/Spinner";
import RequireAuth from "./components/common/RequireAuth";

import Login from "./components/pages/authentication/Login";
import TwoFactorAuth from "./components/pages/authentication/TwoFactorAuth";
import Dashboard from "./components/pages/dashboard/Dashboard";
import PageLayout from "./components/common/PageLayout";
import Users from "./components/pages/users/Users";
import Transactions from "./components/pages/transactions/Transactions";
import TransactionDetails from "./components/pages/transactions/TransactionDetails";
import CoinPrice from "./components/pages/coin-price/CoinPrice";
import Voting from "./components/pages/voting/Voting";
import Companies from "./components/pages/companies/Companies";
import TransactionFees from "./components/pages/transaction-fees/TransactionFees";

const App = () => {
  const user = useAppSelector((state) => state.auth.user);
  let location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    const user = await auth.getUser();
    const status = await auth.getAuthStatus();
    dispatch(authStateChanged({ user, status }));
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, [location]);

  return (
    <div className="app">
      {loading ? (
        <div className="mt-3 container-md text-center">
          <Loading />
        </div>
      ) : (
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/2fa" element={<TwoFactorAuth />} />

          <Route element={<PageLayout />}>
            <Route
              path="/admin"
              element={
                <RequireAuth user={user}>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/price"
              element={
                <RequireAuth user={user}>
                  <CoinPrice />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/users"
              element={
                <RequireAuth user={user}>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/transactions"
              element={
                <RequireAuth user={user}>
                  <Transactions />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/transactions/:transactionId"
              element={
                <RequireAuth user={user}>
                  <TransactionDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/transaction-fees"
              element={
                <RequireAuth user={user}>
                  <TransactionFees />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/companies"
              element={
                <RequireAuth user={user}>
                  <Companies />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/voting"
              element={
                <RequireAuth user={user}>
                  <Voting />
                </RequireAuth>
              }
            />
          </Route>

          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
