import "./FileDisplay.scss";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { config } from "client/config/config";

interface Props {
  fileUrls: string[];
}

const FileDisplay = ({ fileUrls }: Props) => {
  function getFileName(fileUrl: string) {
    let base = process.env.REACT_APP_STORAGE_URL + "/api/file?path=/investor/documents/";
    return fileUrl.replace(base, "");
  }

  function fileTypeIcon(fileUrl: string) {
    const name = getFileName(fileUrl);
    const index = name.lastIndexOf(".");
    const fileType = name.substring(index + 1);
    return "bi-filetype-" + fileType;
  }

  return (
    <div className="file-display">
      <div className="d-flex flex-wrap">
        {fileUrls.map((x) => (
          <div key={x}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <small>{getFileName(x)}</small>
                </Tooltip>
              }
            >
              <div className="file">
                <a target="_blank" href={x} rel="noreferrer">
                  <i
                    className={
                      "file-icon bi bi-file-earmark " + fileTypeIcon(x)
                    }
                  />
                  <div className="file-name">{getFileName(x)}</div>
                </a>
              </div>
            </OverlayTrigger>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileDisplay;
