import { CrudService } from "@appstrax/database";

import { CoinPrice } from "./models/coin-price";

class CoinPriceService extends CrudService<CoinPrice> {
  constructor() {
    super('coin-price', CoinPrice);
  }
}

const coinPriceService = new CoinPriceService();

export {
  coinPriceService
}