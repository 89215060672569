import { useEffect, useState } from "react";
import { SafeTransaction } from "@gnosis.pm/safe-core-sdk-types";
import { EthSignSignature } from "@gnosis.pm/safe-core-sdk";

import { Transaction, User } from "../../../services/models";
import { utilities, gnosisSafeService } from "../../../services";

import Alert from "../../common/Alert";
import If from "../../common/If";
import Spinner from "../../common/Spinner";
import InfoItem from "./InfoItem";
import Button from "../../common/Button";
import TransferTransactionDialog from "./TransferTransactionDialog";
import SignTransactionDialog from "./SignTransactionDialog";

interface Props {
  transaction: Transaction;
  users: User[];
}

const TransactionSignatures = ({ transaction, users }: Props) => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");

  const [isSigned, setIsSigned] = useState(false);
  const [safeTransaction, setSafeTransaction] = useState<SafeTransaction>(null);
  const [safeThreshold, setSafeThreshold] = useState(0);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);

  useEffect(() => {
    if (transaction && transaction.gnosisTransaction) {
      populateSafeTransaction();
    }
  }, [transaction]);

  const populateSafeTransaction = async () => {
    setFetching(true);
    try {
      const safeTransaction = await gnosisSafeService.getSafeTransactionFor(
        transaction
      );
      const isSigned = await gnosisSafeService.hasProviderSigned(transaction);
      const safe = await gnosisSafeService.getSafe();
      const safeThreshold = await safe.getThreshold();

      setSafeTransaction(safeTransaction);
      setSafeThreshold(safeThreshold);
      setIsSigned(isSigned);
    } catch (err) {
      setError(err.message);
    }
    setFetching(false);
  };

  const getUser = (userId: string) => {
    if (!userId) return "";

    const user = users.find((x) => x.id === userId);
    return `${user?.data.name[0]}. ${user?.data.surname}`;
  };

  const getTransactionSignatures = (): EthSignSignature[] => {
    if (safeTransaction) {
      const arr = Array.from(safeTransaction.signatures.entries());
      return arr.map((x) => x[1]) as EthSignSignature[];
    }
    return [];
  };

  const getSignatureCount = (): number => {
    return safeTransaction?.signatures.size || 0;
  };

  return (
    <div className="mt-5">
      <If condition={fetching}>
        <div className="text-center">
          <div className="mt-3">
            <Spinner />
          </div>
        </div>
      </If>

      {!fetching && transaction?.confirmed && (
        <div>
          <h4 className="text-secondary">Transaction Signatures</h4>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Signed At</th>
                  <th>Signed By</th>
                  <th>Signed With Wallet</th>
                </tr>
              </thead>
              <tbody>
                {transaction.signatures.map((signature) => (
                  <tr key={signature.walletAddress}>
                    <td>{utilities.formatDateTime(signature.signedAt)}</td>
                    <td>{getUser(signature.userId)}</td>
                    <td>{signature.walletAddress}</td>
                  </tr>
                ))}

                <If condition={!getTransactionSignatures().length}>
                  <tr>
                    <td colSpan={3}>None</td>
                  </tr>
                </If>
              </tbody>
            </table>
          </div>

          <If condition={!isSigned}>
            <div className="text-center">
              <div className="d-flex justify-content-center mt-2">
                <Button
                  onClick={() => setShowApprovalDialog(true)}
                  variant="success"
                >
                  Approve/Sign Transaction
                </Button>
              </div>
            </div>
          </If>

          <If condition={safeThreshold && getSignatureCount() >= safeThreshold}>
            <div className="mt-5">
              <h4 className=" text-secondary">Transaction Transfer</h4>

              <If condition={!transaction.transferred}>
                <div className="d-flex align-items-center my-2">
                  <div>
                    This transaction is ready to be transferred
                  </div>
                  <Button
                    onClick={() => setShowTransferDialog(true)}
                    className="ms-2"
                    variant="success"
                  >
                    Transfer Tokens
                  </Button>
                </div>
              </If>

              <If condition={transaction.transferred}>
                <div className="row">
                  <div className="col-6 col-md-4 mt-3">
                    <InfoItem title={"Confirmed By"}>
                      {getUser(transaction.transferredBy)}
                    </InfoItem>
                  </div>

                  <div className="col-6 col-md-4 mt-3">
                    <InfoItem title={"Confirmed At"}>
                      {utilities.formatDateTime(transaction.transferredAt)}
                    </InfoItem>
                  </div>

                  <div className="col-6 col-md-4 mt-3">
                    <InfoItem title={"Transferred To"}>
                      <a
                        href={utilities.getEtherscanLink(transaction)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {transaction.walletAddress}
                      </a>
                    </InfoItem>
                  </div>
                </div>
              </If>
            </div>
          </If>
        </div>
      )}

      <Alert error={error} />

      <SignTransactionDialog
        show={showApprovalDialog}
        transaction={transaction}
        onHide={() => setShowApprovalDialog(false)}
      />

      <TransferTransactionDialog
        show={showTransferDialog}
        transaction={transaction}
        users={users}
        onHide={() => setShowTransferDialog(false)}
      />
    </div>
  );
};

export default TransactionSignatures;
