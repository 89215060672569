// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CoinPrice } from '../../services/models/coin-price';

interface CoinPriceState {
  prices: CoinPrice[];
  price: CoinPrice | null;
}

const initialState: CoinPriceState = {
  prices: [],
  price: null
};

const coinPriceSlice = createSlice({
  name: 'coinPrice',
  initialState,
  reducers: {
    coinPricesFetched(state, action: PayloadAction<CoinPrice[]>) {
      state.prices = action.payload.sort((a, b) => {
        const bCreated = new Date(b.createdAt).getTime();
        const aCreated = new Date(a.createdAt).getTime();
        return bCreated - aCreated;
      });
      state.price = state.prices[0];
    },
    coinPriceCreated(state, action: PayloadAction<CoinPrice>) {
      state.prices = [action.payload, ...state.prices];
      state.price = state.prices[0];
    },
    coinPriceUpdated(state, action: PayloadAction<CoinPrice>) {
      const updated = action.payload;

      state.prices = state.prices.map(x => x.id === updated.id ? updated : x);

      if (state.price?.id === action.payload.id) {
        state.price = action.payload;
      }
    },
  },
});

export const {
  coinPricesFetched,
  coinPriceCreated,
  coinPriceUpdated
} = coinPriceSlice.actions;
export default coinPriceSlice.reducer;
