import {
  Applicant,
  InvestorDeclaration,
} from '../../../services/models';
import { DeclarationStatus } from '../../../services/models/investor-declaration';

class UserHelper {

  getApplicantStatus(applicant?: Applicant) {
    if (applicant) {
      return applicant.kycApproved ? "Approved" : "Pending Approval";
    }
    return "Not Started";
  };

  getApplicantColor(applicant?: Applicant) {
    if (applicant?.kycApproved) return "text-success";
    return "text-warning";
  };

  getDeclarationStatus(declaration?: InvestorDeclaration) {
    if (declaration) {
      switch (declaration.status) {
        case DeclarationStatus.pending: return "Pending Approval";
        case DeclarationStatus.approved: return "Approved";
        case DeclarationStatus.declined: return "Declined";
      }
    }
    return "Not Started";
  };

  getDeclarationColor(declaration?: InvestorDeclaration) {
    if (declaration) {
      switch (declaration.status) {
        case DeclarationStatus.pending: return "text-warning";
        case DeclarationStatus.approved: return "text-success";
        case DeclarationStatus.declined: return "text-danger";
      }
    }
    return "text-warning";
  };
}

const userHelper = new UserHelper();

export { userHelper };