import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { transactionFeesFetched } from "../../../redux/slices/transactions-slice";
import { usersFetched } from "../../../redux/slices/users-slice";

import {
  transactionFeeService,
  userService,
  utilities as utils,
} from "../../../services";

import Alert from "../../common/Alert";
import Button from "../../common/Button";
import If from "../../common/If";
import Spinner from "../../common/Spinner";
import TransactionFeeDialog from "./TransactionFeeDialog";

const TransactionFees = () => {
  const dispatch = useAppDispatch();
  const transactionFees = useAppSelector(
    (state) => state.transactions.transactionFees
  );
  const transactionFee = useAppSelector(
    (state) => state.transactions.transactionFee
  );
  const users = useAppSelector((state) => state.users.users);

  const [showDialog, setShowDialog] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!transactionFee) fetchCoinPrices();
  }, [transactionFee]);

  const fetchCoinPrices = async () => {
    setFetching(true);

    try {
      let users = await userService.find();
      let transactionFees = await transactionFeeService.find();
      dispatch(transactionFeesFetched(utils.serializeArr(transactionFees)));
      dispatch(usersFetched(users));
    } catch (err: any) {
      setError(err.message);
    }

    setFetching(false);
  };

  const getUserName = (userId: string) => {
    const user = users.find((x) => x.id === userId);
    if (user) {
      return user.data.name + " " + user.data.surname;
    } else {
      return "No user";
    }
  };

  return (
    <div className="m-3">
      <div className="text-center">
        <div className="mt-3">
          <Spinner show={fetching} />
        </div>
      </div>

      <If condition={!fetching}>
        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={() => setShowDialog(true)}>
            Update Transaction Fee
          </Button>
        </div>

        <table className="table mt-3">
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Percentage</th>
              <th>Flat Amount</th>
              <th>Min Fee</th>
              <th>Max Fee</th>
              <th>Once Off Fee</th>
              <th>Once Off Description</th>
            </tr>
          </thead>
          <tbody>
            {transactionFees.map((x) => (
              <tr
                key={x.id}
                style={{
                  backgroundColor:
                    x.id === transactionFee.id ? "#ff00fe33" : "",
                }}
              >
                <td>{utils.formatDateTime(x.createdAt)}</td>
                <td>{getUserName(x.userId)}</td>
                <td>{x.percentage}%</td>
                <td>{utils.formatRLT(x.flatFee)}</td>
                <td>{utils.formatRLT(x.minAmount)}</td>
                <td>{utils.formatRLT(x.maxAmount)}</td>
                <td>{utils.formatRLT(x.onceOffFee)}</td>
                <td>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={
                      <Popover>
                        <Popover.Body>{x.onceOffFeeDescription}</Popover.Body>
                      </Popover>
                    }
                  >
                    <div
                      style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {x.onceOffFeeDescription}
                    </div>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </If>

      <TransactionFeeDialog
        show={showDialog}
        onHide={() => setShowDialog(false)}
      />

      <Alert error={error} />
    </div>
  );
};

export default TransactionFees;
