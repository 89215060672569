import { useState } from "react";
import Input from "../../common/input/Input";
import "./Users.scss";

export interface FilterData {
  search: string;
  applicantStatus: string;
  declarationStatus: string;
}

interface Props {
  onChange: (filter: FilterData) => void;
}

const UsersFilters = ({ onChange }: Props) => {
  const [search, setSearch] = useState("");
  const [applicantStatus, setApplicantStatus] = useState("");
  const [declarationStatus, setDeclarationStatus] = useState("");

  return (
    <div className="">
      <h4 className="text-secondary">Filters</h4>

      <div className="row align-items-end">
        <div className="col">
          <Input
            label="Search"
            value={search}
            onChange={(x) => {
              setSearch(x);
              onChange({
                search: x,
                applicantStatus,
                declarationStatus,
              });
            }}
          />
        </div>

        <div className="col">
          <label className="col-form-label">KYC Status</label>
          <select
            className="form-select"
            value={applicantStatus}
            onChange={(e) => {
              setApplicantStatus(e.target.value);
              onChange({
                search,
                applicantStatus: e.target.value,
                declarationStatus,
              });
            }}
          >
            <option value="">All</option>
            <option value="Not Started">Not Started</option>
            <option value="Pending Approval">Pending Approval</option>
            <option value="Approved">Approved</option>
          </select>
        </div>

        <div className="col">
          <label className="col-form-label">
            Sophisticated Investor Status
          </label>
          <select
            className="form-select"
            value={declarationStatus}
            onChange={(e) => {
              setDeclarationStatus(e.target.value);
              onChange({
                search,
                applicantStatus,
                declarationStatus: e.target.value,
              });
            }}
          >
            <option value="">All</option>
            <option value="Not Started">Not Started</option>
            <option value="Pending Approval">Pending Approval</option>
            <option value="Approved">Approved</option>
            <option value="Declined">Declined</option>
          </select>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default UsersFilters;
