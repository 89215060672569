import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/auth-slice';
import coinPriceReducer from './slices/coin-price-slice';
import usersReducer from './slices/users-slice';
import transactionsReducer from './slices/transactions-slice';
import companiesReducer from './slices/companies-slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    coinPrice: coinPriceReducer,
    users: usersReducer,
    transactions: transactionsReducer,
    companies: companiesReducer,
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
