import If from "./If";

type Props = {
  error: string;
  className?: string;
};

const Alert = ({ error, ...rest }: Props) => {
  return (
    <If condition={error}>
      <div {...rest}>
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    </If>
  );
};

export default Alert;
