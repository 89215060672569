import { useState } from "react";
import { Modal } from "react-bootstrap";

import "./Companies.scss";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { companyUpdated } from "../../../redux/slices/companies-slice";
import {
  companyService,
  emailService,
  utilities as utils,
} from "../../../services";

import Button from "../../common/Button";
import Alert from "../../common/Alert";

import { Company } from "../../../services/models/company";

type Props = {
  company: Company;
  onHide: () => void;
};

const AcceptCompanyDialog = ({ company, onHide }: Props) => {
  const dispatch = useAppDispatch();

  const me = useAppSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const approveCompany = async () => {
    setLoading(true);

    try {
      company = utils.serialize(company);
      company.approved = true;
      company.approvedBy = me.id;
      company.approvedAt = new Date();

      company = await companyService.save(company);

      emailService.sendCompanyApprovedEmail(company.userId);

      company = utils.serialize(company);
      dispatch(companyUpdated(company));
      onHide();
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Modal show={!!company} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Approval</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="users">
          <div>Are you sure you want to approve {company?.name}?</div>

          <Alert error={error} />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()} variant="secondary">
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={() => approveCompany()}
          variant="primary"
        >
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptCompanyDialog;
