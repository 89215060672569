import axios, { AxiosInstance } from "axios";
import { auth } from "@appstrax/auth";


class KycService {

  private axios: AxiosInstance;

  constructor() {
    const baseURL = process.env.REACT_APP_API_URL;
    this.axios = axios.create({
      baseURL,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  async fetchKycStatusFor(userId: string): Promise<any> {
    const token = await auth.getAuthToken();
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    const res = await this.axios.get(`/api/kyc/applicant/${userId}/status`);
    return res.data.data;
  }
}

const kycService = new KycService();

export { kycService };
