import { CrudService } from "@appstrax/database";

import { InvestorDeclaration } from "./models/investor-declaration";

class InvestorDeclarationService extends CrudService<InvestorDeclaration> {
  constructor() {
    super('investor-declaration', InvestorDeclaration);
  }

  async fetchForUser(userId: string): Promise<InvestorDeclaration> {
    const declarations = await this.find({ userId });
    if (declarations.length) { return declarations[0]; }
    return null;
  }
}

const investorDeclarationService = new InvestorDeclarationService();

export {
  investorDeclarationService
}