import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page } from "react-pdf";

import "./Companies.scss";

import { Company } from "../../../services/models/company";

import If from "../../common/If";

type Props = {
  company: Company | undefined;
  onHide: () => void;
};

const CompanyDialog = ({ company, onHide }: Props) => {
  const pdfHolder = useRef(null);

  const [width, setWidth] = useState<number | undefined>();

  useEffect(() => {
    if (pdfHolder && pdfHolder.current) {
      const elem: any = pdfHolder.current;
      setWidth(elem.clientWidth);
    }
  }, [company]);

  return (
    <Modal
      show={!!company}
      onHide={onHide}
      size="lg"
      keyboard={false}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Document Of Incorporation</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="w-100" ref={pdfHolder}>
          <div className="pdf-holder">
            <Document className="pdf" file={company?.incorporationDocUrl}>
              <Page width={width} pageNumber={1} />
            </Document>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyDialog;
