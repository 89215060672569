import { CrudService } from "@appstrax/database";

import { WalletAddress } from "./models/wallet-address";

class WalletAddressService extends CrudService<WalletAddress> {
  constructor() {
    super('wallet-address', WalletAddress);
  }

  async fetchActiveFor(userId: string): Promise<WalletAddress> {
    const wallets = await this.find({ userId });
    const wallet = wallets.find(x => x.active);
    if (wallet) { return wallet; }
    return null;
  }
}

const walletAddressService = new WalletAddressService();

export {
  walletAddressService
}