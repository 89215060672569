import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./Companies.scss";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { companiesFetched } from "../../../redux/slices/companies-slice";
import { usersFetched } from "../../../redux/slices/users-slice";
import { companyService, userService, utilities as utils } from "../../../services";
import { Company } from "../../../services/models";

import ApproveCompanyModal from "./ApproveCompanyModal";
import CompanyDialog from "./CompanyDocumentModal";
import Alert from "../../common/Alert";
import If from "../../common/If";
import Spinner from "../../common/Spinner";

const Companies = () => {
  const users = useAppSelector((state) => state.users.users);
  const companies = useAppSelector((state) => state.companies.companies);
  const dispatch = useAppDispatch();

  const [fetching, setFetching] = useState(false);
  const [company, setCompany] = useState<Company | undefined>();
  const [companyDocs, setCompanyDocs] = useState<Company | undefined>();
  const [error, setError] = useState("");

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    setFetching(true);

    try {
      if (!users.length) {
        const users = await userService.find();
        dispatch(usersFetched(users));
      }

      let companies = await companyService.find();
      companies = JSON.parse(JSON.stringify(companies));
      dispatch(companiesFetched(companies));
    } catch (err: any) {
      setError(err.message);
    }

    setFetching(false);
  };

  const getUser = (userId: string) => {
    if (!userId) return "";

    const user = users.find((x) => x.id === userId);
    return `${user?.data.name[0]}. ${user?.data.surname}`;
  };

  return (
    <div className="m-3 companies">
      <div className="text-center">
        <div className="mt-3">
          <Spinner show={fetching} />
        </div>
      </div>

      <If condition={!fetching}>
        <table className="table mt-3">
          <thead>
            <tr>
              <th>Trading Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Country</th>
              <th>Registration Number</th>
              <th>Status</th>
              <th>Approved By</th>
              <th>Approved At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((x) => (
              <tr key={x.id}>
                <td>{x.name}</td>
                <td>{x.email}</td>
                <td>{x.phone}</td>
                <td>{x.country}</td>
                <td>{x.registrationNumber}</td>
                <td>
                  <span
                    className={x.approved ? "text-success" : "text-warning"}
                  >
                    {x.approved ? "Approved" : "Pending Approval"}
                  </span>
                </td>
                <td>{getUser(x.approvedBy)}</td>
                <td>
                  <b>{utils.formatDateTime(x.approvedAt)}</b>
                </td>
                <td>
                  <OverlayTrigger
                    overlay={<Tooltip>View Document Of Incorporation</Tooltip>}
                  >
                    <button
                      className="btn btn-info btn-sm me-2"
                      onClick={() => setCompanyDocs(x)}
                    >
                      <i className="bi bi-eye"></i>
                    </button>
                  </OverlayTrigger>
                  <If condition={!x.approved}>
                    <OverlayTrigger
                      overlay={<Tooltip>Approve Company</Tooltip>}
                    >
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => setCompany(x)}
                      >
                        <i className="bi bi-check"></i>
                      </button>
                    </OverlayTrigger>
                  </If>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </If>

      <ApproveCompanyModal
        company={company}
        onHide={() => setCompany(undefined)}
      />

      <CompanyDialog
        company={companyDocs}
        onHide={() => setCompanyDocs(undefined)}
      />

      <Alert error={error} />
    </div>
  );
};

export default Companies;
