import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { transactionFeeCreated } from "../../../redux/slices/transactions-slice";
import { transactionFeeService, utilities } from "../../../services";
import { TransactionFee } from "../../../services/models";

import Button from "../../common/Button";
import Alert from "../../common/Alert";
import Input from "../../common/input/Input";
import TextArea from "../../common/text-area/TextArea";

type Props = {
  show: boolean;
  onHide: () => void;
};

const TransactionFeeDialog = ({ show, onHide }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const currentTransactionFee = useAppSelector(
    (state) => state.transactions.transactionFee
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [percentage, setPercentage] = useState<number>(0);
  const [flatFee, setFlatFee] = useState<number>(0);
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [minAmount, setMinAmount] = useState<number>(0);
  const [onceOffFee, setOnceOffFee] = useState<number>(0);
  const [onceOffFeeDescription, setOnceOffFeeDescription] =
    useState<string>("");

  useEffect(() => {
    if (currentTransactionFee) {
      setPercentage(currentTransactionFee.percentage);
      setFlatFee(currentTransactionFee.flatFee);
      setMaxAmount(currentTransactionFee.maxAmount);
      setMinAmount(currentTransactionFee.minAmount);
      setOnceOffFee(currentTransactionFee.onceOffFee);
      setOnceOffFeeDescription(currentTransactionFee.onceOffFeeDescription);
    }
  }, [currentTransactionFee]);

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      let transactionFee = new TransactionFee();
      transactionFee.userId = user.id;
      transactionFee.percentage = percentage;
      transactionFee.flatFee = flatFee;
      transactionFee.maxAmount = maxAmount;
      transactionFee.minAmount = minAmount;
      transactionFee.onceOffFee = onceOffFee;
      transactionFee.onceOffFeeDescription = onceOffFeeDescription;

      transactionFee = await transactionFeeService.save(transactionFee);
      transactionFee = utilities.serialize(transactionFee);
      dispatch(transactionFeeCreated(transactionFee));

      // TODO: show success message

      onHide();
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Set Transaction Fee</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={(e) => onFormSubmit(e)}>
          <p>
            NOTE: this will set the fees paid by users when purchasing Reality
            Coins.
          </p>

          <Input
            required
            className="mb-4"
            label="Percentage (%)"
            value={percentage || ""}
            onChange={(e) => setPercentage(parseFloat(e))}
            type="number"
            step=".01"
          />

          <Input
            required
            className="mb-4"
            label="Flat Fee (RLT)"
            value={flatFee || ""}
            onChange={(e) => setFlatFee(parseFloat(e))}
            type="number"
            step=".01"
          />

          <Input
            required
            className="mb-4"
            label="Min Amount (RLT)"
            value={minAmount || ""}
            onChange={(e) => setMinAmount(parseFloat(e))}
            type="number"
            step=".01"
          />

          <Input
            required
            className="mb-4"
            label="Max Amount (RLT)"
            value={maxAmount || ""}
            onChange={(e) => setMaxAmount(parseFloat(e))}
            type="number"
            step=".01"
          />

          <p className="mt-3">
            The once off fee is only charged for a buyers very first purchase,
            the purpose of this fee is to cover the KYC and other costs
            involved.
          </p>

          <Input
            required
            className="mb-4"
            label="Once Off Fee (RLT)"
            value={onceOffFee || ""}
            onChange={(e) => setOnceOffFee(parseFloat(e))}
            type="number"
            step=".01"
          />

          <TextArea
            required
            label="Once Off Fee Description"
            value={onceOffFeeDescription}
            onChange={(e) => setOnceOffFeeDescription(e)}
          />
        </form>

        <Alert error={error} />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()} variant="info">
          Cancel
        </Button>
        <Button
          variant="success"
          loading={loading}
          onClick={(e) => onFormSubmit(e)}
        >
          Set Transaction Fee
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionFeeDialog;
