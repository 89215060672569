import { Where, users } from '@appstrax/auth';

import { User } from './models';

class UserService {
  public async find(where?: Where): Promise<User[]> {
    const result = await users.find({ where });
    return result.data as User[];
  }
}

const userService = new UserService();

export { userService };
