import { Model } from "@appstrax/database";

export class Company extends Model {
  userId: string = '';
  name: string = '';
  email: string = '';
  phone: string = '';
  description: string = '';
  country: string = '';
  registrationNumber: string = '';
  incorporationDocUrl: string = '';

  approved: boolean = false;
  approvedBy: string = '';
  approvedAt: Date = null;
}