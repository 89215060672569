// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  User,
  Applicant,
  InvestorDeclaration,
} from './../../services/models';
import { utilities as utils } from '../../services';


interface UsersState {
  users: User[];
  applicants: Applicant[];
  declarations: InvestorDeclaration[];
}

const initialState: UsersState = {
  users: [],
  applicants: [],
  declarations: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    usersFetched(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
    },
    applicantsFetched(state, action: PayloadAction<Applicant[]>) {
      state.applicants = action.payload;
      state.users = state.users.map((x) => {
        x.applicant = state.applicants.find(y => y.userId === x.id);
        return x;
      });
    },
    declarationsFetched(state, action: PayloadAction<InvestorDeclaration[]>) {
      state.declarations = utils.sortByDate(action.payload);
      state.users = state.users.map((x) => {
        x.declaration = state.declarations.find(y => y.userId === x.id);
        return x;
      });
    },
    declarationUpdated(state, action: PayloadAction<InvestorDeclaration>) {
      state.declarations = utils.updateObjectInArr(action.payload, state.declarations);
      state.users = state.users.map((x) => {
        x.declaration = state.declarations.find(y => y.userId === x.id);
        return x;
      });
    },
  },
});

export const {
  usersFetched,
  applicantsFetched,
  declarationsFetched,
  declarationUpdated,
} = usersSlice.actions;
export default usersSlice.reducer;
