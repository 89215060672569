import { ethers, utils } from "ethers";
import { Interface } from "ethers/lib/utils";
import Safe, { EthSignSignature } from "@gnosis.pm/safe-core-sdk";
import EthersAdapter from "@gnosis.pm/safe-ethers-lib";
import { SafeTransaction, SafeTransactionDataPartial } from "@gnosis.pm/safe-core-sdk-types";

import abi from "../abi/reality_coin_abi.json";

import { web3ProviderService as web3Service } from "./web-3-provider.service";
import { Transaction } from "./models";
import { utilities } from "./utilities";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const safeAddress = process.env.REACT_APP_SAFE_ADDRESS;

type Contract = ethers.Contract;

interface ExecuteResult { txHash: string }

class GnosisSafeService {

  private safe: Safe;
  private contract: Contract;
  private address: string;

  public async getSafe(): Promise<Safe> {

    const address = await web3Service.fetchAddress();

    if (this.safe && this.address === address) {
      return this.safe;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(address);

    const ethAdapter = new EthersAdapter({ ethers, signer });

    const safe = await Safe.create({ ethAdapter, safeAddress });
    const contract = new ethers.Contract(contractAddress, abi, provider);

    this.address = address;
    this.safe = safe;
    this.contract = contract;

    return safe;
  }

  public async isProviderASafeOwner(): Promise<boolean> {
    const safe = await this.getSafe();
    return safe.isOwner(this.address);
  }

  public async hasProviderSigned(transaction: Transaction): Promise<boolean> {
    if (await this.isProviderASafeOwner()) {
      const safeTransaction = await this.getSafeTransactionFor(transaction);
      return safeTransaction.signatures.has(this.address);
    }
    return false;
  }

  public async signTransaction(transaction: Transaction): Promise<Transaction> {
    const safe = await this.getSafe();
    const safeTransaction = await this.getSafeTransactionFor(transaction);
    const signedSafeTransaction = await safe.signTransaction(
      safeTransaction,
      "eth_signTypedData"
    );

    transaction = utilities.serialize(transaction);

    transaction.gnosisTransaction = JSON.stringify({
      data: signedSafeTransaction.data,
      signatures: Array.from(signedSafeTransaction.signatures.entries()),
    });

    return transaction;
  }

  public getSafeTransactionFor(transaction: Transaction): Promise<SafeTransaction> {
    if (!transaction.gnosisTransaction) {
      return this.createSafeTransactionFor(transaction);
    } else {
      return this.parseSafeTransactionFrom(transaction);
    }
  }

  private async createSafeTransactionFor(transaction: Transaction): Promise<SafeTransaction> {
    const safe = await this.getSafe();
    const decimals = await this.contract.decimals();
    const rlt = transaction.rltAmount.toString();

    const abiInterface = new Interface(abi);
    const data = abiInterface.encodeFunctionData("transfer", [
      transaction.walletAddress,
      utils.parseUnits(rlt, decimals),
    ]);

    const safeTransactionData: SafeTransactionDataPartial = {
      to: contractAddress,
      value: "0",
      data: data,
    };

    const safeTransaction = await safe.createTransaction({
      safeTransactionData,
    });

    return safeTransaction;
  }

  private async parseSafeTransactionFrom(transaction: Transaction): Promise<SafeTransaction> {
    const safe = await this.getSafe();

    const parsed = JSON.parse(transaction.gnosisTransaction);
    const safeTransaction = await safe.createTransaction({
      safeTransactionData: parsed.data,
    });

    for (let signature of parsed.signatures) {
      const s = new EthSignSignature(
        signature[1].signer,
        signature[1].data
      );
      safeTransaction.addSignature(s);
    }

    return safeTransaction;
  }


  public async executeTransaction(transaction: Transaction): Promise<ExecuteResult> {
    const safe = await this.getSafe();
    const safeThreshold = await safe.getThreshold();
    const safeTransaction = await this.getSafeTransactionFor(transaction);

    if (safeTransaction.signatures.size < safeThreshold) {
      throw new Error('Not enough owners have signed');
    }

    const transactionResult = await safe.executeTransaction(safeTransaction);
    const contractReceipt = await transactionResult.transactionResponse.wait();
    return {
      txHash: contractReceipt.transactionHash
    };
  }
}

const gnosisSafeService = new GnosisSafeService();

export {
  gnosisSafeService
}