import { Model } from "@appstrax/database";

export enum DeclarationStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
}

export class InvestorDeclaration extends Model {
  userId: string = '';

  investorOption: string = '';
  documentUrls: string[] = [];

  status: string = DeclarationStatus.pending;
  statusSetBy: string = '';
  statusSetAt: Date = null;
}