import { Model } from "@appstrax/database";

export enum TransactionApprovalStatus {
  approved = 'approved',
  notApproved = 'notApproved',
}

export class TransactionApproval extends Model {
  transactionId: string = '';
  userId: string = '';
  status: string = '';
}