import { useEffect, useState } from "react";

import "./Dashboard.scss";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { transactionService } from "../../../services/transaction.service";
import { coinPriceService } from "../../../services/coin-price.service";
import { transactionsFetched } from "../../../redux/slices/transactions-slice";
import { coinPricesFetched } from "../../../redux/slices/coin-price-slice";
import { usersFetched } from "../../../redux/slices/users-slice";
import { utilities } from "../../../services/utilities";

import Spinner from "../../common/Spinner";
import Alert from "../../common/Alert";
import If from "../../common/If";
import { TransactionStatus } from "../../../services/models";
import { userService } from "../../../services";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(
    (state) => state.transactions.transactions
  );
  const price = useAppSelector((state) => state.coinPrice.price);
  const users = useAppSelector((state) => state.users.users);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    setLoading(true);
    try {
      const users = await userService.find();
      let transactions = await transactionService.find();
      let prices = await coinPriceService.find();

      transactions = utilities.serializeArr(transactions);
      prices = utilities.serializeArr(prices);

      dispatch(transactionsFetched(transactions));
      dispatch(coinPricesFetched(prices));
      dispatch(usersFetched(users));
    } catch (err: any) {
      setError(err.message);
    }
    setLoading(false);
  };

  const transactionsByStatus = (status: TransactionStatus) => {
    return transactions.filter((x) => x.transactionStatus === status);
  };

  const realityByStatus = (status: TransactionStatus) => {
    return transactionsByStatus(status).reduce(
      (total, x) => total + x.rltAmount,
      0
    );
  };

  return (
    <div className="m-3 dashboard">
      <div className="text-center">
        <Spinner show={loading} />
      </div>

      <If condition={!loading}>
        <div className="d-flex flex-wrap">
          <div className="card mb-3">
            <div className="text-primary">Reality Coin Price</div>
            <div className="value">
              {utilities.formatPounds(price?.price || 0)}
            </div>
          </div>

          <div className="card mb-3">
            <div className="text-primary">Users Signed up</div>
            <div className="value">{users.length}</div>
          </div>

          <div className="card mb-3">
            <div className="text-primary">Reality Transferred</div>
            <div className="value">
              {utilities.formatRLT(realityByStatus(TransactionStatus.approved))}
            </div>
          </div>

          <div className="card mb-3">
            <div className="text-primary">Reality Pending Transfer</div>
            <div className="value">
              {utilities.formatRLT(realityByStatus(TransactionStatus.pending))}
            </div>
          </div>

          <div className="card mb-3">
            <div className="text-primary">All Transactions</div>
            <div className="value">{transactions.length}</div>
          </div>
          <div className="card mb-3">
            <div className="text-primary">Transactions Transferred</div>
            <div className="value">
              {transactionsByStatus(TransactionStatus.approved).length}
            </div>
          </div>
          <div className="card mb-3">
            <div className="text-primary">Pending Transactions</div>
            <div className="value">
              {transactionsByStatus(TransactionStatus.pending).length}
            </div>
          </div>
          <div className="card mb-3">
            <div className="text-primary">Declined Transactions</div>
            <div className="value">
              {transactionsByStatus(TransactionStatus.declined).length}
            </div>
          </div>
        </div>
      </If>

      <Alert error={error} />
    </div>
  );
};

export default Dashboard;
