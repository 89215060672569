import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { User } from "@appstrax/auth";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { transactionUpdated } from "../../../redux/slices/transactions-slice";

import { utilities } from "../../../services/utilities";
import { gnosisSafeService } from "../../../services/gnosis-safe.service";
import { web3ProviderService as web3Service } from "../../../services/web-3-provider.service";
import { transactionService } from "../../../services/transaction.service";
import { emailService } from "../../../services/email.service";

import {
  Transaction,
  TransactionStatus,
} from "../../../services/models/transaction";

import Loading from "../../common/Spinner";
import Button from "../../common/Button";

import Alert from "../../common/Alert";
type Props = {
  transaction?: Transaction;
  users: User[];
  show: boolean;
  onHide: () => void;
};

const expectedChainId = "0x" + process.env.REACT_APP_CHAIN_ID;

const TransferTransactionDialog = ({
  show,
  transaction,
  users,
  onHide,
}: Props) => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (show) {
      initComponent();
      setError("");
    }
  }, [show]);

  const initComponent = async () => {
    setFetching(true);
    try {
      await validateSigner();
    } catch (err) {
      setError(err.message ? err.message : JSON.stringify(err));
    }
    setFetching(false);
  };

  const validateSigner = async () => {
    if (!web3Service.isMetaMask()) {
      throw new Error(
        "Please make sure that MetaMask is installed and active https://metamask.io/"
      );
    }

    const chainId = web3Service.getChainId();
    if (chainId !== expectedChainId) {
      await web3Service.switchToAppChain();
    }

    const isOwner = await gnosisSafeService.isProviderASafeOwner();
    if (!isOwner) {
      throw new Error("Your wallet cannot approve this transaction");
    }
  };

  const getUserName = (userId: string) => {
    const user = users.find((x) => x.id === userId);
    if (user) return user.data.name + " " + user.data.surname;
    return "";
  };

  const transferTransaction = async () => {
    setLoading(true);

    try {
      const result = await gnosisSafeService.executeTransaction(transaction);

      transaction = utilities.serialize(transaction);
      transaction.chainHash = result.txHash;
      transaction.transferred = true;
      transaction.transactionStatus = TransactionStatus.approved;
      transaction.transferredBy = user.id;
      transaction.transferredAt = new Date();

      transaction = await transactionService.save(transaction);
      transaction = utilities.serialize(transaction);
      dispatch(transactionUpdated(transaction));

      emailService.sendTransactionSuccessEmail(transaction);
      onHide();
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Reality</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {fetching ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <div>
            Are you sure you want to transfer{" "}
            {utilities.formatRLT(transaction?.rltAmount || 0)} to{" "}
            {getUserName(transaction?.userId)}'s wallet?
            <div>{transaction?.walletAddress}</div>
          </div>
        )}
        <Alert error={error} />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()} variant="secondary">
          Cancel
        </Button>

        <Button
          className="btn-success"
          loading={loading}
          disabled={fetching || !!error}
          onClick={transferTransaction}
        >
          Transfer Coins
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferTransactionDialog;
