export interface InvestorOption {
  id: string;
  description: string;
}

const investorOptions: InvestorOption[] = [
  {
    id: "A",
    description:
      "I had, throughout the financial year immediately preceding the date below, an annual income to the value of $120,000.00 or more. Annual income for these purposes does not include money withdrawn from my pension savings (except where the withdrawals are used directly for income in retirement).",
  },
  {
    id: "B",
    description:
      "I held, throughout the financial year immediately preceding the date below, net assets to the value of $250,000 or more. Net assets for these purposes do not include: (a) any rights of mine under a qualifying contract of insurance; or (b) any benefits (in the form of pensions or otherwise) which are payable on the termination of my service or on my death or retirement and to which I am (or my dependants are), or may be, entitled; or (c) any withdrawals from my pension savings (except where the withdrawals are used directly for income in retirement).",
  },
  {
    id: "C",
    description:
      "I held, throughout the financial year immediately preceding the date below, total assets to the value of $600,000 or more. (a) any rights of mine under a qualifying contract of insurance; or (b) any benefits (in the form of pensions or otherwise) which are payable on the termination of my service or on my death or retirement and to which I am (or my dependants are), or may be, entitled; or (c) any withdrawals from my pension savings (except where the withdrawals are used directly for income in retirement).",
  },
  {
    id: "D",
    description:
      "I am a member of a network or syndicate of business angels and have been so for at least the last six months prior to the date below.",
  },
  {
    id: "E",
    description:
      "I have made more than one investment in an unlisted company in the two years prior to the date below.",
  },
  {
    id: "F",
    description:
      "I am working or have worked in the two years prior to the date below, in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises.",
  },
  {
    id: "G",
    description:
      "I am currently or have been in the two years prior to the date below, a director of a company with an annual turnover of at least $600,000.00 per annum.",
  },
  {
    id: "H",
    description:
      "who has a written certificate signed within the last 36 months by a firm confirming he has been assessed by that firm as sufficiently knowledgeable to understand the risks associated with engaging in investment activity in non-mainstream investments.",
  },
  {
    id: "I",
    description:
      "who has a written certificate signed within the last 36 months by a firm confirming he has been assessed by that firm as sufficiently qualified as a high net worth individual.",
  },
];

export { investorOptions };