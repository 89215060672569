type Truthy = /*unresolved*/ any;

type Props = {
  condition: Truthy;
  children: React.ReactNode;
};

const If = ({ condition, children }: Props) => {
  return <>{condition ? children : <></>}</>;
};

export default If;
