import { Modal } from "react-bootstrap";

import Button from "./Button";

type Props = {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  title: string;
  body: string;
};

const ConfirmDialog = ({ show, onHide, onConfirm, title, body }: Props) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{body}</Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()} variant="info">
          No
        </Button>
        <Button variant="success" onClick={(e) => onConfirm()}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
