import { useState } from "react";
import { Modal } from "react-bootstrap";
import { auth } from "@appstrax/auth";

// import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
// import { authStateChanged } from "../../../redux/auth/auth-slice";

import Button from "../../common/Button";
import Alert from "../../common/Alert";
import Input from "../../common/input/Input";

type Props = {
  show: boolean;
  onHide: () => void;
};

const ForgotPasswordDialog = ({ show, onHide }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    onForgotPassword();
  };

  const onForgotPassword = async () => {
    setLoading(true);

    try {
      const message = await auth.forgotPassword({ email });
      onHide();
      // TODO: do something with the message
      // message.message;
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Modal
      className="dark"
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="my-5">
          <form onSubmit={(e) => onFormSubmit(e)}>
            <Input
              required
              label="Email"
              value={email}
              onChange={(e) => setEmail(e)}
              type="email"
            />
          </form>

          <Alert error={error} />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()} variant="info">
          Close
        </Button>
        <Button loading={loading} onClick={(e) => onFormSubmit(e)}>
          Forgot Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ForgotPasswordDialog;
