import { Model } from "@appstrax/database";

export class TransactionFee extends Model {
  percentage: number = 0;
  flatFee: number = 0; // in RLT
  maxAmount: number = 0; // in RLT
  minAmount: number = 0; // in RLT
  userId: string = '';

  onceOffFee: number = 0; // in RLT
  onceOffFeeDescription: string = '';
}