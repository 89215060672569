import { CrudService } from "@appstrax/database";

import { TransactionApproval } from "./models/transaction-approval";

class TransactionApprovalService extends CrudService<TransactionApproval> {
  constructor() {
    super('transaction-approvals', TransactionApproval);
  }
}

const transactionApprovalService = new TransactionApprovalService();

export {
  transactionApprovalService
}