import { CrudService } from "@appstrax/database";
import { Applicant } from "./models/applicant";

class ApplicantService extends CrudService<Applicant> {
  constructor() {
    super("applicants", Applicant);
  }
}

const applicantService = new ApplicantService();

export { applicantService };
