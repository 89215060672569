import { CrudService } from "@appstrax/database";

import { Transaction } from "./models/transaction";

class TransactionService extends CrudService<Transaction> {
  constructor() {
    super('transactions', Transaction);
  }
}

const transactionService = new TransactionService();

export {
  transactionService
}