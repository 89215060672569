import Spinner from "./Spinner";

type ButtonType = "button" | "submit" | "reset" | undefined;
type ButtonVariant =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "danger"
  | "light"
  | "link";

type Props = {
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  type?: ButtonType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: ButtonVariant;
};

const Button = ({
  loading = false,
  disabled = false,
  children,
  variant = "primary",
  onClick,
  className,
  ...rest
}: Props) => {
  const classes = `btn btn-${variant} ${className}`;
  return (
    <button
      onClick={onClick}
      className={classes}
      disabled={!!(loading || disabled)}
      style={{ position: "relative" }}
      {...rest}
    >
      {children}

      <span
        style={{
          position: "absolute",
          right: "2px",
          top: "2px",
        }}
      >
        <Spinner show={loading} />
      </span>
    </button>
  );
};
export default Button;
