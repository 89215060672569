import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { coinPricesFetched } from "../../../redux/slices/coin-price-slice";
import { usersFetched } from "../../../redux/slices/users-slice";

import { userService, coinPriceService, utilities } from "../../../services";

import Alert from "../../common/Alert";
import Button from "../../common/Button";
import If from "../../common/If";
import Spinner from "../../common/Spinner";
import ChangePriceDialog from "./ChangePriceDialog";

const CoinPrice = () => {
  const dispatch = useAppDispatch();
  const prices = useAppSelector((state) => state.coinPrice.prices);
  const users = useAppSelector((state) => state.users.users);
  const price = useAppSelector((state) => state.coinPrice.price);

  const [fetching, setFetching] = useState(false);
  const [showPriceDialog, setShowPriceDialog] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!price) fetchCoinPrices();
  }, [price]);

  const fetchCoinPrices = async () => {
    setFetching(true);

    try {
      let users = await userService.find();
      let prices = await coinPriceService.find();
      dispatch(coinPricesFetched(utilities.serializeArr(prices)));
      dispatch(usersFetched(users));
    } catch (err: any) {
      setError(err.message);
    }

    setFetching(false);
  };

  const getUserName = (userId: string) => {
    const user = users.find((x) => x.id === userId);
    if (user) {
      return user.data.name + " " + user.data.surname;
    } else {
      return "No user";
    }
  };

  return (
    <div className="m-3">
      <div className="text-center">
        <div className="mt-3">
          <Spinner show={fetching} />
        </div>
      </div>

      <If condition={!fetching}>
        <div className="d-flex justify-content-between mt-4">
          <h3>
            <b>£{price?.price.toFixed(2)}</b>
          </h3>

          <Button variant="secondary" onClick={() => setShowPriceDialog(true)}>
            Update Coin Price
          </Button>
        </div>

        <table className="table mt-3">
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {prices.map((x) => (
              <tr key={x.id}>
                <td>{utilities.formatDateTime(x.createdAt)}</td>
                <td>{getUserName(x.userId)}</td>
                <td>£{x.price.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </If>

      <ChangePriceDialog
        show={showPriceDialog}
        onHide={() => setShowPriceDialog(false)}
      />

      <Alert error={error} />
    </div>
  );
};

export default CoinPrice;
