import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { auth } from "@appstrax/auth";

import "./auth.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { authStateChanged } from "../../../redux/slices/auth-slice";

import Button from "../../common/Button";
import Alert from "../../common/Alert";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import Input from "../../common/input/Input";
import { AuthStatus } from "@appstrax/auth/dist/models/auth_result";

const Login = () => {
  const status = useAppSelector((state) => state.auth.status);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === AuthStatus.pendingTwoFactorAuthCode) {
      navigate("/auth/2fa");
    }

    if (status === AuthStatus.authenticated) {
      navigate("/admin");
    }
  });

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await auth.login({ email, password, remember: false });
      if (res.status !== AuthStatus.pendingTwoFactorAuthCode) {
        await auth.logout();
        setError(
          "Please configure two factor authentication on the reality company main site"
        );
      } else {
        dispatch(authStateChanged({ status: res.status, user: res.user }));
      }
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  const onForgotPassword = () => {
    setShowForgotPassword(true);
  };

  return (
    <div className="auth">
      <img
        className="background"
        src="/assets/background.jpg"
        alt="Background"
      />
      <div className="p-3">
        <img className="logo" src="/assets/logo-white.png" alt="Reality Logo" />
      </div>

      <div className="center">
        <div className="col-12 col-md-8 col-lg-6 col-xl-4 ">
          <div className="card p-4">
            <h3 className="mb-4">Login</h3>

            <form onSubmit={(e) => onFormSubmit(e)}>
              <Input
                required
                label="Email"
                value={email}
                onChange={(e) => setEmail(e)}
                type="email"
              />

              <Input
                required
                label="Password"
                value={password}
                onChange={(e) => setPassword(e)}
                className="mt-4"
                type="password"
              />

              <div className="mt-4 d-flex justify-content-center">
                <div className="link" onClick={onForgotPassword}>
                  Forgot Password?
                </div>
              </div>
              <div className="d-flex mt-5">
                <Button type="submit" className="w-100" loading={loading}>
                  Login
                </Button>
              </div>
            </form>

            <Alert className="mt-4" error={error} />
          </div>
        </div>
      </div>

      <ForgotPasswordDialog
        show={showForgotPassword}
        onHide={() => setShowForgotPassword(false)}
      ></ForgotPasswordDialog>
    </div>
  );
};

export default Login;
