import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { transactionUpdated } from "../../../redux/slices/transactions-slice";

import { utilities } from "../../../services/utilities";
import { gnosisSafeService } from "../../../services/gnosis-safe.service";
import { web3ProviderService as web3Service } from "../../../services/web-3-provider.service";
import { transactionService } from "../../../services/transaction.service";

import { Transaction } from "../../../services/models/transaction";

import Loading from "../../common/Spinner";
import Button from "../../common/Button";
import Alert from "../../common/Alert";
import If from "../../common/If";

type Props = {
  transaction?: Transaction;
  show: boolean;
  onHide: () => void;
};

const expectedChainId = "0x" + process.env.REACT_APP_CHAIN_ID;

const SignTransactionDialog = ({ show, transaction, onHide }: Props) => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (show) {
      initComponent();
      setError("");
      if (transaction) {
        validateTransaction();
      }
    }
  }, [show]);

  // useEffect(() => {}, [transaction]);

  const initComponent = async () => {
    setFetching(true);
    try {
      await validateSigner();
    } catch (err) {
      setError(err.message ? err.message : JSON.stringify(err));
    }
    setFetching(false);
  };

  const validateSigner = async () => {
    if (!web3Service.isMetaMask()) {
      throw new Error(
        "Please make sure that MetaMask is installed and active https://metamask.io/"
      );
    }

    const chainId = web3Service.getChainId();
    if (chainId !== expectedChainId) {
      await web3Service.switchToAppChain();
    }

    const isOwner = await gnosisSafeService.isProviderASafeOwner();
    if (!isOwner) {
      throw new Error("Your wallet cannot approve this transaction");
    }
  };

  const validateTransaction = async () => {
    try {
      const transactions = await transactionService.find();
      const existing = transactions.find(
        (x) => x.gnosisTransaction && !x.transferred
      );

      if (existing && existing.id !== transaction.id) {
        setError(
          "Please complete the transfer of the transaction with the reference " +
            existing.paymentReference
        );
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const signTransaction = async () => {
    setLoading(true);

    try {
      const address = await web3Service.fetchAddress();
      transaction = await gnosisSafeService.signTransaction(transaction);
      transaction.signatures.push({
        userId: user.id,
        signedAt: new Date(),
        walletAddress: address,
      });

      transaction = await transactionService.save(transaction);
      transaction = utilities.serialize(transaction);
      dispatch(transactionUpdated(transaction));

      onHide();
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Approve/Sign Transaction</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {fetching ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <>
            <If condition={!error}>
              <div>Are you sure you want to sign this transaction?</div>
            </If>
            <Alert error={error} />
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onHide()} variant="secondary">
          Cancel
        </Button>

        <Button
          className="btn-success"
          loading={loading}
          disabled={fetching || !!error}
          onClick={signTransaction}
        >
          Sign Transaction
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SignTransactionDialog;
