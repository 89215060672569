// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Transaction, TransactionFee, TransactionApproval
} from '../../services/models';

interface TransactionState {
  transactions: Transaction[];
  transactionApprovals: TransactionApproval[];
  transactionFees: TransactionFee[];
  transactionFee: TransactionFee;
}

const initialState: TransactionState = {
  transactions: [],
  transactionApprovals: [],
  transactionFees: [],
  transactionFee: null,
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    transactionsFetched(state, action: PayloadAction<Transaction[]>) {
      state.transactions = action.payload.sort((a, b) => {
        const bCreated = new Date(b.createdAt).getTime();
        const aCreated = new Date(a.createdAt).getTime();
        return bCreated - aCreated;
      });
    },
    transactionApprovalsFetched(state, action: PayloadAction<TransactionApproval[]>) {
      state.transactionApprovals = action.payload.sort((a, b) => {
        const bCreated = new Date(b.createdAt).getTime();
        const aCreated = new Date(a.createdAt).getTime();
        return bCreated - aCreated;
      });
    },
    transactionFeesFetched(state, action: PayloadAction<TransactionFee[]>) {
      state.transactionFees = action.payload.sort((a, b) => {
        const bCreated = new Date(b.createdAt).getTime();
        const aCreated = new Date(a.createdAt).getTime();
        return bCreated - aCreated;
      });
      if (state.transactionFees.length) {
        state.transactionFee = state.transactionFees[0];
      } else {
        state.transactionFee = null;
      }
    },
    transactionUpdated(state, action: PayloadAction<Transaction>) {
      state.transactions = state.transactions
        .map(x => x.id === action.payload.id ? action.payload : x);
    },
    transactionFeeCreated(state, action: PayloadAction<TransactionFee>) {
      state.transactionFees = [action.payload, ...state.transactionFees];
      state.transactionFee = state.transactionFees[0];
    },
    transactionApprovalCreated(state, action: PayloadAction<TransactionApproval>) {
      state.transactionApprovals = [action.payload, ...state.transactionApprovals];
    },
  },
});

export const {
  transactionUpdated,
  transactionsFetched,
  transactionFeesFetched,
  transactionApprovalsFetched,
  transactionFeeCreated,
  transactionApprovalCreated,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
