import { Navigate } from "react-router-dom";
import { auth, User } from "@appstrax/auth";

type Props = {
  user: User | null;
  children: React.ReactNode;
};

const RequireAuth = ({ user, children }: Props) => {
  if (user && user.roles.includes("reality-admin")) {
    return <>{children}</>;
  } else {
    if (user) auth.logout();
    return <Navigate to="/auth/login" replace />;
  }
};

export default RequireAuth;
