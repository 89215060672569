// DUCKS pattern
import { User } from '@appstrax/auth';
import {
  AuthResult, AuthStatus
} from '@appstrax/auth/dist/models/auth_result';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  user: User;
  status: AuthStatus;
}

const initialState: AuthState = {
  user: null,
  status: AuthStatus.notAuthenticated,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStateChanged(state, action: PayloadAction<AuthResult>) {
      state.user = action.payload?.user || null;
      state.status = action.payload?.status || AuthStatus.notAuthenticated;
    },
  },
});

export const { authStateChanged } = authSlice.actions;
export default authSlice.reducer;
