import { useState } from "react";

import "./DeclarationBody.scss";
import { investorOptions } from "./investor-options";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { declarationUpdated } from "../../../redux/slices/users-slice";
import { DeclarationStatus } from "../../../services/models/investor-declaration";
import { InvestorDeclaration, User } from "../../../services/models";
import {
  emailService,
  investorDeclarationService,
  utilities as utils,
} from "../../../services";

import FileDisplay from "../../common/file-display/FileDisplay";
import Button from "../../common/Button";
import Alert from "../../common/Alert";
import { userHelper as helper } from "./user-helper";

interface Props {
  declaration?: InvestorDeclaration;
  users: User[];
}

const DeclarationBody = ({ declaration, users }: Props) => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.auth.user);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");

  const option = () => {
    let option = declaration?.investorOption;
    return investorOptions.find((x) => x.id === option);
  };

  const updateDeclaration = async (status: DeclarationStatus) => {
    setSaving(true);

    try {
      setError("");

      declaration = utils.serialize(declaration);
      declaration.status = status;
      declaration.statusSetBy = admin.id;
      declaration.statusSetAt = new Date();

      declaration = await investorDeclarationService.save(declaration);

      if (status === DeclarationStatus.approved) {
        emailService.sendInvestorApprovedEmail(declaration.userId);
      }
      if (status === DeclarationStatus.declined) {
        emailService.sendInvestorDeclinedEmail(declaration.userId);
      }

      declaration = utils.serialize(declaration);

      dispatch(declarationUpdated(declaration));
    } catch (err: any) {
      setError(err.message);
    }

    setSaving(false);
  };

  const getUser = (userId: string) => {
    if (!userId) return "";

    const user = users.find((x) => x.id === userId);
    return `${user?.data.name[0]}. ${user?.data.surname}`;
  };

  return (
    <>
      <div className="mb-1">Declaration:</div>
      <div
        style={{
          padding: "1rem",
          borderRadius: "5px",
          border: "1px solid var(--bs-secondary)",
          fontSize: "80%",
        }}
      >
        {option()?.description}
      </div>

      <div className="mt-2">
        <div className="mb-1">Supporting Documents:</div>
        <FileDisplay fileUrls={declaration?.documentUrls || []} />
      </div>

      {declaration.status === DeclarationStatus.pending && (
        <div className="text-center mt-3">
          <Button
            variant="success"
            className="me-3"
            loading={saving}
            onClick={() => updateDeclaration(DeclarationStatus.approved)}
          >
            <i className="bi bi-check" /> Approve Investor
          </Button>
          <Button
            variant="danger"
            loading={saving}
            onClick={() => updateDeclaration(DeclarationStatus.declined)}
          >
            <i className="bi bi-x" /> Decline Investor
          </Button>
        </div>
      )}

      {declaration.status !== DeclarationStatus.pending && (
        <div className="d-flex justify-content-between mt-2">
          <div>
            {helper.getDeclarationStatus(declaration)} By:{" "}
            {getUser(declaration.statusSetBy)}
          </div>
          <div>
            {helper.getDeclarationStatus(declaration)} At:{" "}
            {utils.formatDateTime(declaration.statusSetAt)}
          </div>
        </div>
      )}

      <Alert error={error} />
    </>
  );
};

export default DeclarationBody;
