import { useEffect, useState } from "react";

import "./Users.scss";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  usersFetched,
  applicantsFetched,
  declarationsFetched,
} from "../../../redux/slices/users-slice";
import { userHelper as helper } from "./user-helper";
import {
  utilities as utils,
  userService,
  applicantService,
  investorDeclarationService as declarationService,
} from "../../../services";
import { User } from "../../../services/models";

import Alert from "../../common/Alert";
import Button from "../../common/Button";
import If from "../../common/If";
import Spinner from "../../common/Spinner";
import UserDetailsDialog from "./UserDetailsDialog";
import UsersFilter, { FilterData } from "./UsersFilters";

const Users = () => {
  const users = useAppSelector((state) => state.users.users);

  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<FilterData>(null);
  const [fetching, setFetching] = useState(false);
  const [user, setUser] = useState<User>(null);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchPageData();
  }, []);

  useEffect(() => {
    if (user) {
      setUser(users.find((x) => x.id === user.id));
    }
  }, [users]);

  const fetchPageData = async () => {
    setFetching(true);

    try {
      const users = await userService.find();
      const applicants = await applicantService.find();
      const declarations = await declarationService.find();

      dispatch(usersFetched(users));
      dispatch(applicantsFetched(utils.serializeArr(applicants)));
      dispatch(declarationsFetched(utils.serializeArr(declarations)));
    } catch (err: any) {
      setError(err.message);
    }

    setFetching(false);
  };

  const filterUsers = (): User[] => {
    let filtered = utils.serializeArr(users);

    if (filter?.search) {
      filtered = filtered.filter((x) => {
        return (
          utils.includes(x.data.name, filter.search) ||
          utils.includes(x.data.surname, filter.search) ||
          utils.includes(x.email, filter.search)
        );
      });
    }

    if (filter?.declarationStatus) {
      filtered = filtered.filter((x) => {
        const status = helper.getDeclarationStatus(x.declaration);
        return status === filter.declarationStatus;
      });
    }

    if (filter?.applicantStatus) {
      filtered = filtered.filter((x) => {
        const status = helper.getApplicantStatus(x.applicant);
        return status === filter.applicantStatus;
      });
    }

    return utils.sortByDate(filtered);
  };

  return (
    <div className="m-3 users">
      <UsersFilter onChange={(filter) => setFilter(filter)} />

      <div className="text-center">
        <div className="mt-3">
          <Spinner show={fetching} />
        </div>
      </div>

      <If condition={!fetching}>
        <table className="table mt-3">
          <thead>
            <tr>
              <th>Register Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>KYC Status</th>
              <th>Sophisticated Investor Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filterUsers().map((x) => (
              <tr key={x.id}>
                <td>{utils.formatDateTime(x.createdAt)}</td>
                <td>
                  {x.data?.name} {x.data?.surname}
                </td>
                <td>
                  <a href={"mailto:" + x.email}>{x.email}</a>
                </td>
                <td>
                  <span className={helper.getApplicantColor(x.applicant)}>
                    {helper.getApplicantStatus(x.applicant)}
                  </span>
                </td>
                <td>
                  <span className={helper.getDeclarationColor(x.declaration)}>
                    {helper.getDeclarationStatus(x.declaration)}
                  </span>
                </td>
                <td>
                  <Button
                    variant="info"
                    className="btn-sm"
                    onClick={() => setUser(x)}
                  >
                    <i className="bi bi-eye" /> Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </If>

      <UserDetailsDialog
        user={user}
        users={users}
        onHide={() => setUser(null)}
      />

      <Alert error={error} />
    </div>
  );
};

export default Users;
