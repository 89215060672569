// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '../../services/models/company';

interface CompanyState {
  companies: Company[];
}

const initialState: CompanyState = {
  companies: [],
};

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    companiesFetched(state, action: PayloadAction<Company[]>) {
      state.companies = action.payload;
    },
    companyUpdated(state, action: PayloadAction<Company>) {
      const company = action.payload;
      state.companies = state.companies.map(x => {
        return x.id === company.id ? company : x;
      });
    },
  },
});

export const {
  companiesFetched,
  companyUpdated,
} = companySlice.actions;
export default companySlice.reducer;
