import { v4 as uuidv4 } from 'uuid';
import { ethers } from "ethers";

import abi from "../abi/reality_coin_abi.json";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const expectedChainNumber = parseInt(process.env.REACT_APP_CHAIN_ID);
const expectedChainId = '0x' + process.env.REACT_APP_CHAIN_ID;
// const expectedChainName = process.env.REACT_APP_CHAIN_NAME;

interface ProviderData {
  address: string,
  chainId: string
}

export interface Listener {
  id: string;
  onChange: (data: ProviderData) => void;
}

export interface ChainTransaction {
  from: string,
  to: string,
  amount: number,
  blockNumber: number,
  transactionHash: string,
}

class Web3ProviderService {

  private address: string;
  private chainId: string;
  private listeningToChanges = false;
  private listeners: Listener[] = [];

  public async fetchAddress(): Promise<string> {
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    this.address = accounts[0];
    return this.address;
  }

  public getChainId(): string {
    this.chainId = window.ethereum.chainId;
    return this.chainId;
  }

  subscribeToAddressChanges(onChange: (data: ProviderData) => void): string {
    const id = uuidv4();

    this.listeners.push({
      id,
      onChange: onChange,
    });

    if (this.address && this.chainId) {
      onChange({ address: this.address, chainId: this.chainId });
    }
    if (!this.listeningToChanges) { this.startListeningToAddressChanges(); }

    return id;
  }

  unsubscribeFromAddress(id: string) {
    this.listeners = this.listeners.filter(x => x.id !== id);
  }

  private async startListeningToAddressChanges() {
    if (this.listeningToChanges || !this.isInstalled()) {
      return;
    }

    window.ethereum.on('accountsChanged', (accounts) => {
      this.address = accounts[0];
      this.updateListeners();
    });

    window.ethereum.on('chainChanged', (chainId) => {
      this.chainId = chainId;
      this.updateListeners();
    });

    this.listeningToChanges = true;

    if (!this.address || !this.chainId) {
      await this.fetchAddress();
      this.getChainId();
      this.updateListeners();
    }
  }

  private updateListeners() {
    for (const listener of this.listeners) {
      listener.onChange({
        address: this.address,
        chainId: this.chainId,
      });
    }
  }

  isInstalled() {
    return typeof window?.ethereum !== "undefined";
  }

  isMetaMask(): boolean {
    return this.isInstalled() &&
      window.ethereum.isMetaMask &&
      !window.ethereum.isBraveWallet;
  }

  isBraveWallet(): boolean {
    return this.isInstalled() && window.ethereum.isBraveWallet;
  }

  isCoinbaseWallet(): boolean {
    return this.isInstalled() && window.ethereum.isCoinbaseWallet;
  }

  async switchToAppChain() {
    if (window.ethereum.chainId !== expectedChainId) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: expectedChainId }],
      });
    }
  }

  addRealityCoin() {
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: contractAddress,
          symbol: 'RLT',
          decimals: 18,
          image: "https://storage.realitycompany.tech/api/file?path=images/icon64.png",
        },
      },
    });
  }


  async fetchTransactionsFor(address: string): Promise<ChainTransaction[]> {
    if (address !== this.address) {
      return []
    }

    const contract = this.getContractFor(address);
    const rxFilter = contract.filters.Transfer(null, address);
    const rxResults = await contract.queryFilter(rxFilter);

    const txFilter = contract.filters.Transfer(address, null);
    const txResults = await contract.queryFilter(txFilter);

    let tx: ChainTransaction[] = rxResults.map((x) => {
      let amount = ethers.utils.formatUnits(x.args[2]);
      return {
        from: x.args[0],
        to: x.args[1],
        amount: parseFloat(amount),
        blockNumber: x.blockNumber,
        transactionHash: x.transactionHash,
      };
    });

    let rx: ChainTransaction[] = txResults.map((x) => {
      let amount = ethers.utils.formatUnits(x.args[2]);
      return {
        from: x.args[0],
        to: x.args[1],
        amount: parseFloat(amount),
        blockNumber: x.blockNumber,
        transactionHash: x.transactionHash,
      };
    });

    return [...tx, ...rx].sort((a, b) => b.blockNumber - a.blockNumber);
  }

  async fetchBalanceFor(address: string): Promise<number> {
    const contract = this.getContractFor(address);
    let balance = await contract.balanceOf(address);
    balance = ethers.utils.formatUnits(balance);
    return parseFloat(balance)
  }

  private getContractFor(address: string): ethers.Contract {
    let provider: ethers.providers.Provider;
    if (address === this.address) {
      provider = new ethers.providers.Web3Provider(window.ethereum);
    } else {
      const apiKey = "N2DPQZ3K74H5CJN7646XHK9X3HWS3RRZAQ";
      provider = new ethers.providers.EtherscanProvider(expectedChainNumber, apiKey);
    }
    return new ethers.Contract(contractAddress, abi, provider);
  }


}

const web3ProviderService = new Web3ProviderService();

export {
  web3ProviderService
}