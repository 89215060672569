import { TransactionApproval, TransactionApprovalStatus } from './models/transaction-approval';
import { PaymentMethod, PaymentStatus, Transaction, TransactionStatus } from "./models";
import { Model } from '@appstrax/database';

class Utilities {
  serialize<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
  }

  serializeArr<T>(objects: T[]): T[] {
    return objects.map(x => this.serialize(x));
  }

  formatDate(date: string | Date): string {
    if (!date) { return ''; }
    date = new Date(date);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
  }

  public formatDateTime(date: Date) {
    if (!date) { return ''; }
    date = new Date(date);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes} ${this.formatDate(date)}`;
  }

  getTransactionStatus(transaction: Transaction) {
    switch (transaction.transactionStatus) {
      case TransactionStatus.pending: return "Pending Approval";
      case TransactionStatus.approved: return "Approved";
      case TransactionStatus.declined: return "Declined";
    }
  };

  formatTransactionApprovalStatus(approval: TransactionApproval) {
    switch (approval.status) {
      case TransactionApprovalStatus.approved: return "Approved";
      case TransactionApprovalStatus.notApproved: return "Not Approved";
    }
  };

  getStatusClass(transaction: Transaction) {
    switch (transaction.transactionStatus) {
      case TransactionStatus.pending: return "text-warning";
      case TransactionStatus.approved: return "text-success";
      case TransactionStatus.declined: return "text-danger";
    }
  };

  getPaymentStatusClass(transaction: Transaction) {
    switch (transaction.paymentStatus) {
      case PaymentStatus.pending: return "text-warning";
      case PaymentStatus.paid: return "text-success";
      case PaymentStatus.failed: return "text-danger";
      case PaymentStatus.cancelled: return "text-danger";
    }
  };

  // getEtherscanLink(transaction: Transaction) {
  //   return `https://goerli.etherscan.io/tx/${transaction.chainHash}`;
  // };

  public getEtherscanLink(transaction: Transaction) {

    let subdomain = '';
    switch (process.env.REACT_APP_CHAIN_ID) {
      case '5':
        subdomain = 'goerli';
        break;
      case '1':
        subdomain = 'www';
        break;
    }

    return `https://${subdomain}.etherscan.io/tx/${transaction.chainHash}`;
  };

  getPaymentMethod(transaction: Transaction) {
    switch (transaction.paymentMethod) {
      case PaymentMethod.bankTransfer: return "Bank Transfer";
      case PaymentMethod.card: return "Card";
      case PaymentMethod.uniswap: return "Uniswap";
    }
  };

  getPaymentStatus(transaction: Transaction) {
    switch (transaction.paymentStatus) {
      case PaymentStatus.pending: return "Pending";
      case PaymentStatus.paid: return "Paid";
      case PaymentStatus.failed: return "Failed";
      case PaymentStatus.cancelled: return "Cancelled";
    }
  };

  public thousandSeparate(number: string | number): string {
    const parts = number.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, " ") + (decimalPart ? "." + decimalPart : "");
  }

  public formatPounds(value: number) {
    if (value !== 0 && !value) { return ''; }
    return '£' + this.thousandSeparate(value.toFixed(2));
  }

  public formatRLT(value: number) {
    if (value !== 0 && !value) { return ''; }
    return this.thousandSeparate(value.toFixed(2)) + ' RLT';
  }


  public sortByDate<T extends Model>(objects: T[]): T[] {
    return objects.sort((a, b) => {
      const bCreated = new Date(b.createdAt).getTime();
      const aCreated = new Date(a.createdAt).getTime();
      return bCreated - aCreated;
    });
  }

  public updateObjectInArr<T extends Model>(object: T, objects: T[]): T[] {
    return objects.map(x => x.id === object.id ? object : x);
  }

  public includes(input: string, searchTerm: string): boolean {
    return input.toLowerCase().includes(searchTerm.toLowerCase());
  }
}

const utilities = new Utilities();

export {
  utilities
}