import { CrudService } from "@appstrax/database";

import { TransactionFee } from "./models/transaction-fee";

class TransactionFeeService extends CrudService<TransactionFee> {
  constructor() {
    super('transaction-fees', TransactionFee);
  }
}

const transactionFeeService = new TransactionFeeService();

export {
  transactionFeeService
}