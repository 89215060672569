import { useEffect, useState } from "react";

import "./Transactions.scss";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import {
  Transaction,
  TransactionStatus,
} from "../../../services/models/transaction";
import { transactionService } from "../../../services/transaction.service";
import { utilities as utils } from "../../../services/utilities";
import {
  transactionApprovalsFetched,
  transactionsFetched,
} from "../../../redux/slices/transactions-slice";
import { usersFetched } from "../../../redux/slices/users-slice";

import Alert from "../../common/Alert";
import If from "../../common/If";
import Spinner from "../../common/Spinner";
import { Link } from "react-router-dom";
import { transactionApprovalService, userService } from "../../../services";

const Transactions = () => {
  const dispatch = useAppDispatch();

  const transactions = useAppSelector(
    (state) => state.transactions.transactions
  );
  const approvals = useAppSelector(
    (state) => state.transactions.transactionApprovals
  );
  const users = useAppSelector((state) => state.users.users);
  const me = useAppSelector((state) => state.auth.user);

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    setFetching(true);

    try {
      let approvals = await transactionApprovalService.find();
      let transactions = await transactionService.find();
      let users = await userService.find();

      transactions = utils.serializeArr(transactions);
      approvals = utils.serializeArr(approvals);

      dispatch(transactionApprovalsFetched(approvals));
      dispatch(transactionsFetched(transactions));
      dispatch(usersFetched(users));
    } catch (err: any) {
      setError(err.message);
    }

    setFetching(false);
  };

  const getUser = (userId: string) => {
    if (!userId) return "";

    const user = users.find((x) => x.id === userId);
    return `${user?.data.name[0]}. ${user?.data.surname}`;
  };

  const requiresApproval = (transaction: Transaction): boolean => {
    return (
      transaction.transactionStatus === TransactionStatus.pending &&
      !transaction.transferred &&
      !approvals.find(
        (x) => x.transactionId === transaction.id && x.userId === me.id
      )
    );
  };

  return (
    <div className="m-3 transactions">
      <div className="text-center">
        <div className="mt-3">
          <Spinner show={fetching} />
        </div>
      </div>

      <If condition={!fetching}>
        <div className="table-responsive">
          <table className="table mt-3">
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Status</th>
                <th>Payment Status</th>
                <th>Requires Approval</th>
                <th>Transferred</th>
                <th>Coins</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((x) => (
                <tr key={x.id}>
                  <td>{utils.formatDateTime(x.createdAt)}</td>
                  <td>{getUser(x.userId)}</td>
                  <td>
                    <span className={utils.getStatusClass(x)}>
                      {utils.getTransactionStatus(x)}
                    </span>
                  </td>
                  <td>
                    <span className={utils.getPaymentStatusClass(x)}>
                      {utils.getPaymentStatus(x)}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        requiresApproval(x) ? "text-warning" : "text-success"
                      }
                    >
                      {requiresApproval(x) ? "Yes" : "No"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        x.transferred ? "text-success" : "text-warning"
                      }
                    >
                      {x.transferred ? "Yes" : "No"}
                    </span>
                  </td>
                  <td>
                    <b>{utils.formatRLT(x.rltAmount)}</b>
                  </td>
                  <td>
                    <Link
                      className="btn btn-info"
                      to={"/admin/transactions/" + x.id}
                    >
                      <i className="bi bi-eye"></i>
                    </Link>
                  </td>
                </tr>
              ))}

              <If condition={!transactions.length}>
                <tr>
                  <td colSpan={9}>None</td>
                </tr>
              </If>
            </tbody>
          </table>
        </div>
      </If>

      <Alert error={error} />
    </div>
  );
};

export default Transactions;
