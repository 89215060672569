import { auth } from "@appstrax/auth";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";

import userSvg from "./user.svg";
import questionSvg from "./question.svg";

import { authStateChanged } from "../../redux/slices/auth-slice";
import { useAppDispatch } from "../../redux/hooks";

import "./PageLayout.scss";
import If from "./If";
import Button from "./Button";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const PageLayout = () => {
  const links = [
    {
      name: "Dashboard",
      icon: "bi-clipboard2-data",
      link: "/admin",
    },
    {
      name: "Coin Price",
      icon: "bi-coin",
      link: "/admin/price",
    },
    {
      name: "Transaction Fees",
      icon: "bi-tags",
      link: "/admin/transaction-fees",
    },
    {
      name: "Users",
      icon: "bi-people",
      link: "/admin/users",
    },
    {
      name: "Transactions",
      icon: "bi-wallet",
      link: "/admin/transactions",
    },
    {
      name: "Companies",
      icon: "bi-building",
      link: "/admin/companies",
    },
  ];

  const [showMenu, setShowMenu] = useState(true);
  const dispatch = useAppDispatch();
  const [width] = useWindowSize();
  const route = useLocation();

  const logout = async () => {
    await auth.logout();
    dispatch(authStateChanged(null));
  };

  useEffect(() => {
    if (width < 990) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [route, width]);

  return (
    <div className="page-layout">
      <div className={"sidebar " + (!showMenu ? "active " : "")}>
        <div>
          <Link to="/">
            <div className="logo">
              <img src="/assets/logo-white.png" alt="logo" />
            </div>
          </Link>

          <div className="mt-5">
            {links.map((x) => (
              <Link
                key={x.link}
                className={
                  (x.link === route.pathname ? "active" : "") + " nav-item"
                }
                to={x.link}
              >
                <div className="me-3 icon-holder">
                  <i className={"icon bi " + x.icon}></i>
                </div>
                <div>{x.name}</div>
              </Link>
            ))}
          </div>
        </div>
        <div>
          <Button variant="primary" className="w-100 transparent">
            <img className="icon" src={questionSvg} alt="icon" />
            <span className="ms-2">Support</span>
          </Button>
        </div>
      </div>

      <div className="content-holder">
        <nav className="nav">
          <div>
            <h4 className="text-center bold">
              {route.pathname === "/user/profile"
                ? "Profile"
                : links.find((x) => x?.link === route?.pathname)?.name}
            </h4>
          </div>
          <div className="d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle variant="light">
                <img src={userSvg} alt="icon" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item onClick={() => navigate("/user/settings")}>
                  Profile
                </Dropdown.Item> */}
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <If condition={width < 990}>
              <div className="menu ms-3" onClick={() => setShowMenu(!showMenu)}>
                <If condition={!showMenu}>
                  <i className="bi bi-list" />
                </If>
                <If condition={showMenu}>
                  <i className="bi bi-x-lg" />
                </If>
              </div>
            </If>
          </div>
        </nav>

        <div className="content">
          <Container>
            <Outlet />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
