import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { auth } from "@appstrax/auth";

import "./auth.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { authStateChanged } from "../../../redux/slices/auth-slice";

import Button from "../../common/Button";
import Alert from "../../common/Alert";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import Input from "../../common/input/Input";
import { AuthStatus } from "@appstrax/auth/dist/models/auth_result";

const TwoFactorAuth = () => {
  const status = useAppSelector((state) => state.auth.status);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [code, setCode] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === AuthStatus.notAuthenticated) {
      navigate("/auth/login");
    }
    if (status === AuthStatus.authenticated) {
      navigate("/admin");
    }
  });

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const user = await auth.verifyTwoFactorAuthCode(code);
      if (user.roles.includes("reality-admin")) {
        dispatch(
          authStateChanged({
            status: AuthStatus.authenticated,
            user,
          })
        );
      } else {
        auth.logout();
        setError(
          "Not Enough Permission! Contact your administrator to gain access"
        );
        setTimeout(() => {
          navigate("/auth/login");
        }, 3000);
      }
    } catch (err: any) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <div className="auth">
      <img
        className="background"
        src="/assets/background.jpg"
        alt="Background"
      />
      <div className="p-3">
        <img className="logo" src="/assets/logo-white.png" alt="Reality Logo" />
      </div>

      <div className="center">
        <div className="col-12 col-md-8 col-lg-6 col-xl-4 ">
          <div className="card p-4">
            <h3 className="mb-4">Two Factor Authentication</h3>

            <form onSubmit={(e) => onFormSubmit(e)}>
              <Input
                required
                label="Code"
                value={code}
                onChange={(e) => setCode(e)}
              />

              <div className="d-flex mt-5">
                <Button type="submit" className="w-100" loading={loading}>
                  Authenticate Admin
                </Button>
              </div>
            </form>

            <Alert className="mt-4" error={error} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
