import { CrudService } from "@appstrax/database";

import { Company } from "./models/company";


class CompanyService extends CrudService<Company> {
  constructor() {
    super('company', Company);
  }
}

const companyService = new CompanyService();

export {
  companyService
}