import axios, { AxiosInstance } from "axios";
import { auth, User } from "@appstrax/auth";

import { Transaction } from "./models/transaction";

class EmailService {

  private axios: AxiosInstance;

  constructor() {
    const baseURL = process.env.REACT_APP_API_URL;
    this.axios = axios.create({
      baseURL,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  async sendTransactionSuccessEmail(transaction: Transaction) {
    const token = await auth.getAuthToken();
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return this.axios.post(`/api/email/transaction-success`, {
      transactionId: transaction.id
    });
  }

  async sendTransactionDeclinedEmail(transaction: Transaction) {
    const token = await auth.getAuthToken();
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return this.axios.post(`/api/email/transaction-declined`, {
      transactionId: transaction.id
    });
  }

  async sendCompanyApprovedEmail(investorId: string) {
    const token = await auth.getAuthToken();
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return this.axios.post(`/api/email/company-approved`, {
      userId: investorId,
    });
  }

  async sendInvestorApprovedEmail(investorId: string) {
    const token = await auth.getAuthToken();
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return this.axios.post(`/api/email/investor-approved`, {
      userId: investorId,
    });
  }

  async sendInvestorDeclinedEmail(investorId: string) {
    const token = await auth.getAuthToken();
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return this.axios.post(`/api/email/investor-declined`, {
      userId: investorId,
    });
  }
}

const emailService = new EmailService();

export {
  emailService
}