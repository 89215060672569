import { Model } from "@appstrax/database";

export class Applicant extends Model {
  applicantId: string = '';
  inspectionId: string = '';
  correlationId: string = '';
  clientId: string = '';
  levelName: string = '';
  userId: string = '';
  reviewStatus: string = '';
  kycApproved: boolean = false;
}
